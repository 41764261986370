import * as React from 'react'
import {Link as LinkModel} from "../store/models/register-page";
import {scrollToTield} from "../helpers/scroll-to-tield";
import * as styles from "../styles/components/page-nav.module.scss"
import StyledText, {TextStyles} from "./typography";

type Props = {
    title: string | null,
    links: LinkModel[] | null,
    benefitAnchor?: string | null,
}

const PageNav = (props: Props) => {
    const {title, links, benefitAnchor} = props;
    return (
        <div className={styles.container}>
            <StyledText variant={TextStyles.h1} tag="h1">
                {title}
            </StyledText>

            <nav className={styles.linksContainer}>
                {benefitAnchor &&
                    <a
                        href='#benefit'
                        onClick={() => scrollToTield('benefitAnchor')}
                        className={`t-link ${styles.link}`}
                    >
                        {benefitAnchor}
                    </a>
                }
                {links?.map((link: LinkModel) => (
                    <a
                        href={link.url?.link}
                        className={`t-link ${styles.link}`}
                        key={link.label}
                    >
                        {link.label}
                    </a>
                ))}
            </nav>
        </div>
    )
}

export default PageNav
