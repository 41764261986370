// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var ctaTitle = "cta-double-card-module--ctaTitle--eba4a";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var grid = "cta-double-card-module--grid--94dcc";
export var icon = "cta-double-card-module--icon--6afc6";
export var left = "cta-double-card-module--left--d602f";
export var primaryColor = "#fff";
export var right = "cta-double-card-module--right--c3e08";
export var secondaryColor = "#000";
export var text = "cta-double-card-module--text--b8b25";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";
export var title = "cta-double-card-module--title--b43fc";