import * as Scroll from 'react-scroll';

const scroller = Scroll.scroller;

export function scrollToTield(id: string): void {
    scroller.scrollTo(id, {
        duration: 1500,
        smooth: true,
        offset: -225,
        container: id
    });
}
