import React from "react";
import * as styles from "../styles/components/logo-container.module.scss"
import {ImageModel} from "../store/models/image";
import ProgressiveImage from "./common/ProgressiveImage";

type Props = {
  img?: ImageModel | null,
}

const LogoContainer = (props: Props) => {
  const {img} = props

  return (
    <div className={styles.container}>
        {img && <ProgressiveImage image={img} />}
    </div>
  )
}

export default LogoContainer
