import * as React from 'react'
import {useEffect} from 'react'
import {Layout} from "../components/Layout";
import PageNav from "../components/PageNav";
import {useSelector} from "react-redux";
import {RootState} from "../store/reducers";
import {useActions} from "../store/hooks";
import LogoContainer from "../components/LogoContainer";
import CtaDoubleCard from "../components/cta/CtaDoubleCard";
import {PartnersPageModel} from "../store/models/partners-page";
import SEO from "../components/SEO";
import * as styles from "../styles/pages/partners.module.scss";
import StyledText, {TextStyles} from "../components/typography";
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import {MenuModel} from "../store/models/menu";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";

type PartnersPageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse
    }
}

const PartnersPage = (props: PartnersPageProps) => {
    const partnersPageModel: PartnersPageModel = PartnersPageModel.fromJSON(props.serverData.page);
    const menuModel: MenuModel = MenuModel.fromJSON(props.serverData.menu);

    return (
        <>
            <SEO seo={partnersPageModel.seo}></SEO>

            <Layout
                menu={menuModel}
                slotAboveFooter={<CtaDoubleCard ctaCard={partnersPageModel.ctaCard}/>}
            >
                <PageNav title={partnersPageModel.title} links={partnersPageModel.links}/>
                {partnersPageModel.filteredPartners && partnersPageModel.filteredPartners.map((item) => (
                    <section className={styles.section}>
                        <StyledText variant={TextStyles.h2} tag="h2" className={styles.sectionTitle}>
                            {item.title}
                        </StyledText>

                        <div className={styles.grid}>
                            {item.partners && item.partners.map((partnerLink) => (
                                <article>
                                    <LogoContainer img={partnerLink.partner?.logo}/>
                                </article>
                            ))}
                        </div>
                    </section>
                ))}
            </Layout>
        </>
    )
}

export default PartnersPage

export async function getServerData(context: GetServerDataProps): GetServerDataReturn  {
    try {
        const locale: string = context.pageContext.locale as string;
        const strapi = new StrapiClient(locale);
        const page = await strapi.getSingleType(StrapiSingleTypes.partnersPage);
        const menu = await strapi.getSingleType(StrapiSingleTypes.menu);

        return {
            props: {
                page,
                menu
            },
        }
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}
