// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var container = "page-nav-module--container--81cd7";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var link = "page-nav-module--link--677c9";
export var linksContainer = "page-nav-module--linksContainer--637f5";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";